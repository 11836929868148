<template>
  <div class="order-list">
    <nav-bar :nav-info='navInfo' ></nav-bar>
    <form action="javascript:return ture">
        <van-search
          v-model="searchValue"
          placeholder="请输入搜索订单号"
          @search="onSearch"
        />
    </form>
    <!-- 订单列表 -->
      <div class="list" v-if="list">
        <div class="orderInfo_show" v-for="(item,index) in list" :key="index">
          <p class="orderInfo_show_orderNo">
            <span>订单号：{{item.waybillNo}}</span>
            <span v-if="item.status==21"><van-tag plain  type="danger" >待调度</van-tag></span>
            <span v-if="item.status==22"><van-tag plain color="#f87026">未开始</van-tag></span>
            <span v-if="item.status==23"><van-tag plain type="primary">在途</van-tag></span>
            <span v-if="item.status==24"><van-tag plain type="success">已完成</van-tag></span>
            <span v-if="item.status==25"><van-tag plain type="warning">已取消</van-tag></span>
          </p>
          <div class="orderInfo_show_list">
            <div class="orderInfo_show_list_left" @click.stop="selectList(item,index)">
              <van-radio-group v-model="radio" class="select_radio" >
                <van-radio :name="item.waybillNo" ></van-radio>
              </van-radio-group>
            </div>
            <div class="orderInfo_show_list_rigth">
              <p class="rigth_receiverName">
                <span>司机：{{item.realName}}</span>
                <span>车牌号：{{item.carNo}}</span>
              </p>
              <p class="rigth_receiverName">
                <span>电话：{{item.phone}}</span>
              </p>
            </div>     
          </div>
        </div>
      </div>
   
     <!-- 底部 -->
    <div class="bottom">
      <div class="btnDispatch">
        上传回执单
      </div>
     <div class="btnDispatch">结束运单</div>
    </div>
  </div>
</template>
<script>
import NavBar from '../../components/NavBar/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      //navbar信息
      navInfo:{
        title:'运单列表',
        left:true,
        rigth:false,
        path:'/waybillListdetail'
      },
      list: [],
      searchValue:'',
      radio:'',

     
    }
  },
  created() {
    this.getData(this.$route.query.waybillNo)
  },
  mounted() {
  },
  methods: {
    //触发搜索框根据订单号搜索
    async getData(value) {
      console.log(this.$route.query);
      let params = {
        waybillNo:value
      }
      let res = await this.$Api.queryByWaybillNo(params)
      console.log(res);
      if (res.data.code == 200) {
        this.list = res.data.data
      }
    },
    selectList(item,index) {
      console.log(item,index);
    },
    //触发搜索框根据订单号搜索
    async onSearch(value) {
      console.log(value);
      this.getData(value)
  }
}
}
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
}
.order-list {
  height: 100%;
  margin-bottom: 59px;
  
  // .van-list {
  //   height: 80%;
  //   overflow: auto;

  // }
// 返回顶部
 .backTop {
      position: fixed;
      bottom: 70px;
      right: 20px;
      // border: 1px solid pink;
      width: 20px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .orderInfo_show {
    padding:5px 15px;
    border-bottom: 1px solid #e4e1e1;
    .orderInfo_show_orderNo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        
      }
      span:nth-child(1) {
        flex: 5;
        text-align: left;
      }
      span:nth-child(2) {
        flex: 1;
        text-align: right;
      }
    }
    .orderInfo_show_list{
      font-size: 14px;
      display: flex;
      align-items: center;
      .orderInfo_show_list_left {
        width: 30px;
      }
      .orderInfo_show_list_rigth {
        flex: 1;
        color: #bbb9b9;
        .rigth_receiverName {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            flex: 1;
            padding: 5px 0;
          }
        }
      }
    }
  }

}
.bottom {
  z-index: 9999;
  height: 49px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  // background-color: #f58374;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  .btnDispatch {
    flex: 1;
   text-align: center;
   line-height: 49px;
   font-size: 18px;
   color: #FFFFFF;
    &:nth-child(1) { 
      background-color: #f6be35; 
     } 
    &:nth-child(2) { 
      background-color: #f87026; 
    } 
  }
    
}
</style>